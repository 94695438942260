#root {
    /* Set the background of the entire app */
    --ion-background-color: #fff;
  }

  ion-header {
    --ion-background-color: #fff;
  }

  ion-toolbar {
    --ion-background-color: #fff;
  }

  .email-icon {
    --ion-background-color: #222222;
  }

  ion-searchbar {
    width: 3vh;
  }

  .header {
    margin: auto;
    text-align: center;
    font-family: brandon-grotesque;
  }
  
  .title {
    font-size: 38px;
    letter-spacing: .01em;
    line-height: 1.45em;
    text-transform: none;
    font-weight: 400;
    font-style: normal;
  }

  .info {
    font-size: 15px;
    letter-spacing: .25em;
    line-height: 2em;
    text-transform: uppercase;
    font-weight: 500;
    font-style: normal;
  }

