#root {
  /* Set the background of the entire app */
  --ion-background-color: #fff;
}
ion-searchbar {
  --color: #2222;
  --box-shadow: ; 
  --border-radius: 2px;
}


ion-list {
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
}


ion-item {
  margin: auto;
  border-width: 5px;
  border-color: black;
  display: flex;
  align-self: stretch;
}

ion-card {
  border-width: 5px;
  border-color: black;
  align-self: stretch;
}

ion-card-header.header {
  border-bottom: 10px;
  border-color: black
}


ion-card-title {
  font-family: brandon-grotesque;
  font-size: 20px;
  border-bottom: 2em;
  border-color: black
}

ion-card-content {
  font-family: brandon-grotesque;
  font-size: 15px;
  text-transform: uppercase;
}
/* .searchbar-input.sc-ion-searchbar-md {
  padding-top: 6px;
  padding-bottom: 6px;
  background-position: left 8px center;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  width: 80%;
  text-align: center;
  margin: auto;
} */

/* .container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
} */